import "./Footer.css";


function Footer() {
  
  
  return (
    <div className="footer">
      <h4>TrAveL by TAL</h4>
    </div>
  );
}

export default Footer;
