import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import store from "../../../Redux/Store";
import UserModel from "../../../Models/UserModel";
import AuthMenu from "../../AuthArea/AuthMenu/AuthMenu";
import { NavLink } from "react-router-dom";

function Header(): JSX.Element {
  const [user, setUser] = useState<UserModel>(null);
  const navigate = useNavigate();
  const [closeNavBar, setCloseNavBar] = useState<boolean>(false);

  useEffect(() => {
    setUser(store.getState().authState.user);

    const unsubscribeMe = store.subscribe(() => {
      setUser(store.getState().authState.user);
    });

    return () => unsubscribeMe();
  }, []);

  const handleCloseNavBar = () => {
    if (closeNavBar) {
      setCloseNavBar(!closeNavBar);
    } else {
      setCloseNavBar(closeNavBar);
    }
  };

  return (
    <div className="Header">
      <div className="logo">
        <NavLink to="/home">TrAveL</NavLink>
      </div>

      {user !== null && user.role === "Admin" ? (
        <div className="menu">
          <NavLink to="/vacations">Vacations</NavLink> |
          <NavLink to="/vacations/reports">Reports</NavLink> |
          <NavLink to="/vacations/new">Add new vacation</NavLink>
        </div>
      ) : (
        <div className="menu">
        <NavLink to="/vacations">Vacations</NavLink>
        </div>
      )}

      <div className="authMenu">
        <AuthMenu handleCloseNavBar={handleCloseNavBar} />
      </div>
    </div>
  );
}

export default Header;
