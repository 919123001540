import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import UserModel from "../../../Models/UserModel";
import store from "../../../Redux/Store";
import "./AuthMenu.css";

interface AuthMenuProps {
  handleCloseNavBar: () => void;
}

function AuthMenu(args: AuthMenuProps): JSX.Element {
  const [user, setUser] = useState<UserModel>(null);
  useEffect(() => {
    setUser(store.getState().authState.user);

    const unSubscribeMe = store.subscribe(() => {
      setUser(store.getState().authState.user);
    });

    return () => unSubscribeMe();
  }, []);

  return (
    <div className="AuthMenu">
      {user === null ? (
        <span>Hello Guest  &nbsp; |   <NavLink className="login" to="/login"> Login </NavLink>
        </span>
      ) : (
        <>
          <span>Hello {user.firstName} {user.lastName}&nbsp;&nbsp;</span>
          <span> | </span>
          <NavLink to="/logout">Logout</NavLink>
        </>
      )}
    </div>
  );
}

export default AuthMenu;
